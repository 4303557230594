$icons: (
    website: 0,
    email: 1,
    chat: 2,
    fax: 3,
    phone: 4,
    pin: 5,
    details: 7,
    video: 8,
    share: 9,
    watchlist: 10,
    print: 11,
    admin: 12,
    close: 13,
    revert: 14,
);

@function iconX($variant, $size: 5) {
    @return (($variant - 1) * $size * -5px);
}

@function iconY($name, $size: 5) {
    $row: map-get($icons, $name);

    @return ($row * $size * (-5.4px));
}

@mixin icon($name, $size: 5, $variant: 1, $position: '', $hover: true) {
    $svgWidth: 4 * 25px;
    $svgHeight: (length($icons) + 1) * 27px;

    $selector: "&";
    @if ($position == 'before' or $position == 'after') {
        $selector: "&:"+$position;
    }

    #{$selector} {
        position: relative;
        flex-shrink: 0;
        display: block;
        width: $size * 5px;
        height: $size * 5.4px;
        background: url("../images/icons.svg") iconX($variant, $size) iconY($name, $size) no-repeat;
        background-size: ($svgWidth * .2 * $size) ($svgHeight * .2 * $size);

        @if ($position == 'before' or $position == 'after') {
            content: "";
            margin-right: $size * 3px;
        } @else {
            border: none;
            text-indent: -999em;
        }

        @if ($hover) {
            cursor: pointer;
        }

        @content;
    }

    @if ($hover) {
        &:hover {
            #{$selector} {
                background-position: iconX(3, $size) iconY($name, $size);
            }
        }

        &:active {
            #{$selector} {
                background-position: iconX(4, $size) iconY($name, $size);
            }
        }
    }
}
