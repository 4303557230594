@import "defaults";

@media print {
    body {
        color: #000;
    }

    .print-logo {
        display: block !important;
        padding: 20px 0;
        text-align: center;
        border-bottom: 3px solid map-get($borders, default);

        img {
            width: 30%;
        }
    }

    .print-hidden,
    .section-header,
    .section-subheader,
    .section-service,
    .section-prefooter,
    .section-footer,
    .ce_hyperlink.back {
        display: none;
    }

    .section-main .mod_article__inside {
        max-width: none;
        padding: 0;
    }

    .location-details {
        max-width: none;
        print-color-adjust: exact;

        &__header {
            display: block;
            margin: 20px 0;
        }

        &__name {
            padding: 10px 20px;
            color: #fff;
            background: map-get($backgrounds, button);
            border-radius: 6px;

            strong {
                color: #fff;
                font-weight: map-get($font-weight, bold);
            }
        }

        &__name + .location-details__row,
        &__name + .location-details__row + .location-details__row {
            overflow: hidden;
            float: left;
            width: 48%;
            padding-right: 2%;
            padding-bottom: 20pt;

            &.location-details__section-margin {
                margin: 0 !important;

                .location-details__column:first-child h2 {
                    margin-top: 0;
                }
            }
        }

        .location-details__section {
            clear: both;
            overflow: hidden;
            margin: 20pt 0 0;
            padding: 0;
            border: none;
            break-inside: avoid;
        }

        .location-details__row--print {
            overflow: hidden;

            .location-details__column {
                float: left;
                width: 48%;
                padding-right: 2%;
            }
        }

        h2, h3 {
            margin: 10pt 0 2pt;

            + p {
                margin-top: 2pt;
            }
        }

        .location-details__list {
            li {
                padding-top: 0;
                padding-bottom: 0;
                font-size: inherit;
            }
        }

        h2 + .location-details__row h3:first-child {
            margin-top: 0;
        }

        &__map {
            display: none;
        }
    }

    .si-location {
        break-inside: avoid;
        display: block;
        clear: both;
        overflow: hidden;
        padding: .5em 0;
        font-size: 12pt;
        line-height: 1.3;
        border: none;
        border-radius: 0;
        border-top: 1px solid map-get($borders, default);
        box-shadow: none;

        &__about {
            width: auto;
            padding: 0;
        }

        &__print-row {
            float: left;
            width: 29%;
            padding-right: 1vw;

            &--name {
                width: 40%;
            }

            &--links {
                padding-right: 0;
            }
        }

        &__address {
            margin: 0;
            color: #000 !important;

            span {
                display: block;

                &:after {
                    content: none;
                }
            }
        }

        &__contact--collapsed {
            display: block;

            .si-location__contact-link {
                print-color-adjust: exact;
                display: flex;
                width: auto !important;
                height: auto !important;
                margin: 0 !important;
                padding: 0 !important;
                color: #000 !important;
                text-indent: 0 !important;
                white-space: normal !important;
                text-overflow: unset;
                background: none;

                &--website {
                    @include icon(website, 4, 4, before, false);
                }

                &--email {
                    @include icon(email, 4, 4, before, false);
                }

                &--chat {
                    @include icon(chat, 4, 4, before, false);
                }

                &--phone {
                    @include icon(phone, 4, 4, before, false);
                }
            }
        }

        &__unpublished,
        &__watchlist,
        &__offering,
        &__actions {
            display: none;
        }
    }
}
